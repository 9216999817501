<template>
  <div v-if="classroomView">
    <v-app>
      <v-row class="">
        <v-col
          md="4"
          sm="6"
          xs="12"
          :class="'mt-3'"
          v-for="classroom in classrooms"
          :key="classroom.id"
        >
          <v-hover v-slot="{ hover }">
            <router-link
              :to="'/classrooms/detail/' + classroom.id"
              class="router-class"
            >
              <v-card width="400" :class="'class-highlight'">
                <v-img height="200px" :src="classroom.image_thumbnail">
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        transition-fast-in-fast-out
                        white
                        darken-2
                        v-card--reveal
                        display-3
                        white--text
                      "
                      style="height: 100%"
                    >
                      {{ classroom.name }}
                    </div>
                  </v-expand-transition>
                  <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-spacer></v-spacer>

                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                          :style="'z-index: 99'"
                          @click.stop.prevent
                          v-if="btn"
                        >
                          <v-icon :style="'color: #244CA3'"
                            >mdi-dots-vertical</v-icon
                          >
                        </v-btn>
                      </template>

                      <v-card
                        class="mx-auto"
                        max-height="300px"
                        min-width="30%"
                        tile
                      >
                        <v-list dense>
                          <v-list-item-group color="primary" min-width="150">
                            <v-list-item
                              :to="'/classrooms/edit/' + classroom.id"
                            >
                              <v-list-item-content> Edit </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="classDelete(classroom.id)">
                              <v-list-item-content> Hapus </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-app-bar>
                </v-img>
                <v-card-text :style="card_text_place">
                  <template v-if="hover">
                    <h5 class="card-title mb-4" style="color: #244CA3">
                      {{ classroom.room }}
                    </h5>
                    <p class="" style="color: #244CA3">
                      {{
                        classroom.description.length > 200
                          ? classroom.description.substring(0, 200) + '...'
                          : classroom.description
                      }}
                    </p>
                    <p class="classroom-room" style="color: #244CA3">
                      {{ classroom.name }}
                    </p>

                    <div class="d-flex align-items-center">
                      <div class="teacher-name">
                        <span style="color: #244CA3">{{
                          classroom.teacher_name
                        }}</span>
                      </div>
                      <div class="image-input-wrapper ml-2 teacher-photo">
                        <b-img
                          :src="classroom.teacher_photo"
                          rounded="circle"
                          alt="Circle image"
                          style="opacity: 0.5"
                        ></b-img>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <h5 class="card-title mb-4">{{ classroom.room }}</h5>
                    <b-card-text>
                    </b-card-text>
                    <b-card-text>
                      {{
                        classroom.description.length > 200
                          ? classroom.description.substring(0, 200) + '...'
                          : classroom.description
                      }}
                    </b-card-text>
                    <p class="card-text classroom-room">
                      {{ classroom.name }}
                    </p>
                    <div class="d-flex align-items-center">
                      <div class="teacher-name">
                        <span>{{
                          classroom.teacher_name
                        }}</span>
                      </div>
                      <div class="image-input-wrapper ml-2 teacher-photo">
                        <b-img
                          :src="classroom.teacher_photo"
                          rounded="circle"
                          alt="Circle image"
                        ></b-img>
                      </div>
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </router-link>
          </v-hover>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  name: "Classroom",
  data() {
    return {
      mainProps: { blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1' },
      classrooms: [],
      teachers: [],
      card_text_place: "min-height: 170px",
      // access
      classroomView: false,
      btn: false,
      btnJoin: false,
    };
  },
  methods: {
    async getClassrooms() {
      let currentPage = "";
      let perPage = "";
      let response = await module.paginate(
        `api/classrooms/my-classrooms/${getUser().id}`,
        `?page=${currentPage}&page_size=${perPage}`
      );

      this.classrooms = response.data;
      if (this.classrooms.length > 0) {
        let env = this.classrooms[0].image_header.split("/storage")[0];
        for (let a = 0; a < this.classrooms.length; a++) {
          this.classrooms[a].teacher_photo =
            env + this.classrooms[a].teacher_photo;
        }
      }
    },
    async classDelete(id) {
      // Delete Data
      let result = await module.delete(`api/classrooms/${id}`);
      // If Deleted
      if (result) {
        this.getClassrooms();
        this.$root.$emit("refreshClassroom");
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1001") {
          this.classroomView = true
        }
        if (access_right[a] == "1002") {
          this.btn = true
          this.classroomView = true
        }
        if (access_right[a] == "1003") {
          this.btnJoin = true
          this.classroomView = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kelas Saya" }]);
    this.checkAccess()
  },
  created() {
    this.getClassrooms();
  },
};
</script>

<style scoped>
.card-title {
  color: black;
}

.card-text {
  color: rgb(94, 92, 92) !important;
}

.teacher-name {
  color: rgb(94, 92, 92);
  position: absolute;
  bottom: 15px;
}
.teacher-photo {
  color: rgb(94, 92, 92);
  position: absolute;
  bottom: 6px;
  right: 10px;
}

.classroom-room {
  position: absolute;
  bottom: 16px;
}

.class-highlight:hover {
  color: #244CA3 !important;
}

.router-class:hover {
  color: #244CA3 !important;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.image-input-wrapper {
  width: 40px !important;
  height: 40px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>